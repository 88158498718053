<template>
  <div class="insight_calendar">
    <el-card style="border-radius: 10px">
      <h2 style="font-size: 20px; margin-bottom: 10px">投放日历</h2>
      <div style="display: flex; margin-bottom: 10px">
        <div style="flex: 1; margin-top: 2px">
          <div style="margin-bottom: 10px">
            <el-radio v-model="cost" label="cost" disabled>费用</el-radio>
            <el-radio-group v-model="echartsType" size="mini">
              <el-radio label="exposure">曝光</el-radio>
              <el-radio label="posts">数量</el-radio>
            </el-radio-group>
          </div>
          <el-radio-group
            v-model="platform"
            size="mini"
            style="margin-bottom: 20px"
          >
            <el-radio label="all">All</el-radio>
            <el-radio label="wechat">微信</el-radio>
            <el-radio label="weibo">微博</el-radio>
            <el-radio label="douyin">抖音</el-radio>
            <el-radio label="xiaohongshu">小红书</el-radio>
            <el-radio label="bilibili">哔哩哔哩</el-radio>
          </el-radio-group>
        </div>
        <div style="display: flex">
          <div
            style="
              margin-top: 2px;
              width: 600px;
              display: flex;
              justify-content: flex-end;
              flex-wrap: wrap;
            "
          >
            <legend-com
              v-for="legend in echartsLegend"
              :key="legend.name"
              :data="legend"
            />
          </div>
          <el-radio-group v-model="echartsTime" size="mini">
            <el-radio-button label="day">天</el-radio-button>
            <el-radio-button label="week">周</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <line-bar
        style="height: 400px"
        :xAxis="echartsxAxis"
        :yAxis="echartsyAxis"
        :series="echartsSeries"
        :color="echartsColor"
      />
    </el-card>
  </div>
</template>

<script>
import legendCom from "../Dashboard/legendCom";
import lineBar from "./lineBar";
import { getComparisonCalendar } from "@/api/campaignInsight";

export default {
  name: "insightCalendar",
  data() {
    return {
      cost: "cost",
      echartsType: "exposure",
      echartsTime: "day",
      platform: "all",
      echartsLegend: [],
      echartsxAxis: {
        data: [],
        type: "category",
      },
      echartsSeries: [],
      echartsColor: ["#7CA526", "#5E9AF4", "#5B4A99"],
      defaultColors: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getCalendar();
  },
  computed: {
    params() {
      return {
        id: this.id,
        xType: this.echartsTime,
        yType: this.echartsType,
        platform: this.platform,
      };
    },
    echartsyAxis() {
      if (this.echartsType === "exposure") {
        return "曝光";
      } else {
        return "数量";
      }
    },
  },
  methods: {
    async getCalendar() {
      let { data } = await getComparisonCalendar(this.params);
      // yAxis1为cost数据
      data = data.filter((item) => item.trend.length);
      if (data.length) {
        this.originData = [].concat(data);
        this.initTrendData(data);
      } else {
        this.echartsLegend = [];
        this.echartsxAxis.data = [];
        this.echartsSeries = [];
      }
    },
    initTrendData(data) {
      this.echartsLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        this.echartsLegend.push({
          name: campaign.name,
          color: this.echartsColor.concat(this.defaultColors)[i],
        });
        startTimeArr.push(campaign.trend[0].xAxis);
        const lastIndex = campaign.trend.length - 1;
        endTimeArr.push(campaign.trend[lastIndex].xAxis);
      });
      if (this.echartsTime === "day") {
        // day
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    formatDayDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime);
    },
    getDayAllDate(start, end) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      const oneDay = 24 * 60 * 60 * 1000;
      for (stampDb; stampDb <= stampDe; stampDb += oneDay) {
        dateArr.push(this.timeStampToDate(stampDb));
      }
      this.echartsxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatWeekDate(startTimeArr, endTimeArr) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime);
    },
    getWeekAllDate(start, end) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stampDb; stampDb <= stampDe; stampDb += oneWeek) {
        stampArr.push(stampDb);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.echartsxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    formatMonthDate(startTimeArr, endTimeArr) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime);
    },
    getMonthAllDate(start, end) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.echartsxAxis.data = dateArr;
      this.getDataFromOrigin();
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      this.echartsSeries = [];
      let tempArr = [];
      this.originData.forEach((campaign, i) => {
        const seriesItem1 = {
          data: [],
          name: campaign.name + "费用",
          type: "line",
          smooth: true,
          color: this.echartsColor[i],
        };
        const seriesItem2 = {
          data: [],
          name: campaign.name + this.echartsyAxis,
          type: "line",
          smooth: true,
          yAxisIndex: 1,
          color: this.echartsColor[i],
        };
        // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
        const startTimeIndex = this.echartsxAxis.data.indexOf(
          campaign.trend[0].xAxis
        );
        const endTimeIndex = this.echartsxAxis.data.indexOf(
          campaign.trend[campaign.trend.length - 1].xAxis
        );
        const campaignxAxis = this.echartsxAxis.data.slice(
          startTimeIndex,
          endTimeIndex + 1
        );
        this.echartsxAxis.data.forEach((xAxis) => {
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            if (trend) {
              seriesItem1.data.push(trend.yAxis1 / 100);
              seriesItem2.data.push(trend.yAxis2);
            } else {
              seriesItem1.data.push(0);
              seriesItem2.data.push(0);
            }
          } else {
            seriesItem1.data.push(null);
            seriesItem2.data.push(null);
          }
        });
        tempArr.push(seriesItem1, seriesItem2);
      });
      this.echartsSeries = tempArr;
    },
  },
  watch: {
    params() {
      this.getCalendar();
    },
  },
  components: {
    legendCom,
    lineBar,
  },
};
</script>

<style lang="scss">
.insight_calendar {
  flex: 1;

  .el-radio-group {
    .is-checked {
      .el-radio__inner {
        background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
      }
    }

    .el-radio {
      .el-radio__input {
        .el-radio__inner {
          border-color: #e09a29;
        }
      }

      .el-radio__label {
        color: #979797;
      }
    }

    .is-active {
      .el-radio-button__inner {
        background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
        border-color: #e09a29;
        color: #fff !important;
      }
    }

    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      box-shadow: -1px 0 0 0 #e09a29;
    }

    .el-radio-button {
      .el-radio-button__inner {
        background-color: #f8f9fe;
        color: #979797;
      }
    }
  }
}
</style>
