<template>
  <div class="lineDiv" ref="chart"></div>
</template>

<script>
/*
 * 传x轴(日期)，默认为空
 * 传Y轴（数据），默认为空
 * */

import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);
//对Proxy对象进行拆箱。
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);

import { nextTick } from "vue";

export default {
  name: "line-bar",
  data() {
    return {
      myChart: "",
      timer: null,
      defaultColors: [
        "#c23531",
        "#2f4554",
        "#61a0a8",
        "#d48265",
        "#91c7ae",
        "#749f83",
        "#ca8622",
        "#bda29a",
        "#6e7074",
        "#546570",
        "#c4ccd3",
      ],
    };
  },
  props: {
    xAxis: {
      type: Object,
      default() {
        return {
          type: "category",
          data: [],
        };
      },
    },
    yAxis: {
      type: String,
      default: "曝光",
    },
    series: {
      type: Array,
      default() {
        return [
          {
            type: "line",
            name: "全体网民触达",
            data: [],
            smooth: true,
          },
        ];
      },
    },
    color: {
      typ: Array,
      default() {
        return ["#113491", "#bfd9ef", "#65a6ea"];
      },
    },
  },
  computed: {
    formatSeries() {
      return this.series.map((item) => {
        return Object.assign(
          {
            lineStyle: {
              width: 4,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#999", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#eee", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              opacity: 0.1,
            },
          },
          item
        );
      });
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.chart);
      this.resize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawLineBar() {
      unwarp(this.myChart).setOption({
        grid: {
          show: false,
          top: 30,
          right: 14,
          bottom: 30,
          left: 14,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },
        xAxis: Object.assign(
          {
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              margin: 20,
              fontSize: 14,
              formatter: (value) => {
                const i = value.indexOf(" ~ ");
                return i === -1
                  ? value
                  : value.slice(0, i + 3) + "\n" + value.slice(i + 3);
              },
            },
            splitLine: {
              show: false,
            },
          },
          this.xAxis
        ),
        yAxis: [
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              margin: 20,
              fontSize: 14,
              formatter: (value) => {
                return value > 1000000
                  ? value / 1000000 + "M"
                  : value > 1000
                  ? value / 1000 + "K"
                  : value;
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#ECE9F1",
              },
            },
            name: "费用",
            type: "value",
          },
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              margin: 20,
              fontSize: 14,
              formatter: (value) => {
                return value > 1000000
                  ? value / 1000000 + "M"
                  : value > 1000
                  ? value / 1000 + "K"
                  : value;
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#ECE9F1",
              },
            },
            name: this.yAxis,
            type: "value",
          },
        ],
        series: this.formatSeries,
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#A2A3A5",
        },
        color: this.color.concat(this.defaultColors),
      });
    },
    resize() {
      this.myChart.resize();
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 500);
      });
    },
  },
  watch: {
    series() {
      this.drawLineBar();
    },
  },
};
</script>

<style scoped lang="scss">
.lineDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
